.thumbnail-container .rpv-thumbnail__list--vertical {
    display: flex !important;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

}
.thumbnail-container .container-automatic-split-loader .rpv-thumbnail__items {
    /* width: 10% !important; */
    align-items: start;
    
}

.container-automatic-split-loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
.grid-fixed-pos .automatic-split-loader{
    width: 3rem;
    height: 3rem;
    color: white !important;
}

#headingFour .accordion-button::after {
    width: unset !important
}

#headingFour select {
    padding: 0 0 0 5px !important
}

.rpv-thumbnail__list {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    align-content: flex-start !important;
    align-items: center !important;
}
.rpv-core__page-layer--single:hover{
    cursor: all-scroll;
}
.swich-icon-continer .form-check-input:checked {
    background-color: rgb(108, 117, 125);
    border-color: rgb(108, 117, 125);
}
.fileSplitModal .modal-content {
        background-color: rgba(255, 255, 255, 0);
        border: none;
}

.fileSplitModal .modal-dialog    {
        margin-left: 20%;
}
.single-splited-pdf{
      max-height: 175px;
}
.single-splited-pdf img {
    max-width: 100%;
}

.loading {
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    text-align: center;
    span {
        display: inline-block;
        margin: 0 -0.05em;
    }
    position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
}

.loading03 span {
  margin: 0 -0.075em;
  animation: loading03 1.0s infinite alternate;
}
.loading03 span:nth-child(2) {
  animation-delay: 0.1s;
}
.loading03 span:nth-child(3) {
  animation-delay: 0.2s;
}
.loading03 span:nth-child(4) {
  animation-delay: 0.3s;
}
.loading03 span:nth-child(5) {
  animation-delay: 0.4s;
}
.loading03 span:nth-child(6) {
  animation-delay: 0.5s;
}
.loading03 span:nth-child(7) {
  animation-delay: 0.6s;
}
.loading03 span:nth-child(8) {
  animation-delay: 0.7s;
}
.loading03 span:nth-child(9) {
  animation-delay: 0.8s;
}
.loading03 span:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes loading03 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
.loading03 span{
    margin:0 3px;
}