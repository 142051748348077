@mixin tablet-and-up {
    @media screen and (min-width: 769px) {
        @content;
    }
}

@mixin mobile-and-up {
    @media screen and (min-width: 601px) {
        @content;
    }
}

@mixin tablet-and-down {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin mobile-only {
    @media screen and (max-width: 600px) {
        @content;
    }
}

.dashboard-timeline ul,
li {
    list-style: none;
    padding: 0;
}

.container-timeline {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    padding: 0 0;
}

.wrapper-timeline {
    background: #ffffff;
    padding: 2rem;
    border-radius: 15px;
}

.dashboard-timeline h1 {
    font-size: 1.1rem;
    font-family: sans-serif;
}

.sessions-timeline {
    margin-top: 2rem;
    border-radius: 12px;
    position: relative;
}

.dashboard-timeline li {
    padding-bottom: 1.5rem;
    border-left: 1px solid #176c89;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
}

.dashboard-timeline li:last-child {
    border: 0px;
    padding-bottom: 0;
}

.dashboard-timeline li:before {
    content: '';
    width: 15px;
    height: 15px;
    background: white;
    border: 1px solid #1ba1a7;
    box-shadow: 3px 3px 0px #176c89;
    box-shadow: 3px 3px 0px #176c89;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: 0px;
}

.time-timeline {
    color: #2a2839;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;

    @include mobile-and-up {
        font-size: .9rem;
    }

    @include mobile-only {
        margin-bottom: .3rem;
        font-size: 0.85rem;
    }

}

.dashboard-timeline p {
    color: #4f4f4f;
    font-family: sans-serif;
    line-height: 1.5;
    margin-top: 0.4rem;

    @include mobile-only {
        font-size: .9rem;
    }
}